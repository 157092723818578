<template>
  <div class="batchBox">
    <el-form
      ref="detail"
      :model="detail"
      label-position="left"
      :rules="rules"
      size="mini"
    >
      <div class="title"><i class="el-icon-collection-tag" />必填基本资料</div>
      <div class="form">
        <el-form-item label="客户名称" prop="customer_name">
          <el-input
            v-model="detail.customer_name"
            class="w200"
            placeholder="请填写客户姓名"
          />
        </el-form-item>
        <el-form-item label="归属门店" prop="shop_id" >
          <el-input
            v-if="id != 0"
            v-model="detail.shop_name"
            class="w200"
            readonly
          />
          <el-select
            v-else
            v-model="detail.shop_id"
            class="w200"
            filterable
            remote
            :remote-method="getShop"
            @change="changeShop"
            placeholder="请输入归属门店"
          >
            <el-option
              v-for="item in options.shop_id"
              :key="item.shopId"
              :label="item.shopName"
              :value="item.shopId"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="手机号码" prop="telephone">
          <el-input
            v-model="detail.telephone"
            class="w200"
            placeholder="请填写手机号码"
            @blur="blurPhone(detail.telephone)"

          />
        </el-form-item>

        <el-form-item label="客户来源" prop="source_id">
          <el-select
            v-model="detail.source_id"
            class="w200"
            placeholder="请选择来源"
            :disabled="disabled"
            @change="changeSource"
          >
            <el-option
              v-for="item in options.source_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="渠道来源(门诊)" prop="source_type_id" v-if="customerSourceShow">
          <el-select
            v-model="detail.source_type_id"
            class="w200"
            placeholder="请选择客户来源"
            :disabled="disabled"
          >
            <el-option
              v-for="item in options.source_type_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="治疗进展" prop="repair_progress">
          <el-select
            v-model="detail.repair_progress"
            class="w200"
            placeholder="请选择治疗进展"
          >
            <el-option
              v-for="item in options.repair_progress"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="疤痕资料" prop="scar_type_position">
          <el-cascader
            placeholder="请选择疤痕位置/类型"
            class="w300"
            :options="scarList"
            :value="scar_type_position"
            :props="props"
            clearable
            @change="changeOp"
          ></el-cascader>
        </el-form-item>

        <div class="title center">
          <i class="el-icon-collection-tag" />选填基本资料
        </div>
        <el-form-item label="客户性别">
          <el-radio-group v-model="detail.sex">
            <el-radio :label="2">女</el-radio>
            <el-radio :label="1">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="客户生日">
          <el-date-picker
            v-model="detail.birthday"
            class="w200"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="所在地区" prop="countryId" class="region">
          <region
            v-if="id != 0 && region.province_id"
            :detail="region"
            size="mini"
            @getRegion="getRegion"
          />
          <region v-else :detail="region" size="mini" @getRegion="getRegion" />
        </el-form-item>
        <el-form-item label="详细地市" prop="detailed_address">
          <el-input
            v-model="detail.detailed_address"
            class="w200"
            placeholder="请填写详细地址"
          />
        </el-form-item>

        <el-form-item label="首次进店时间" v-if="userInfo.shop_id">
          <el-date-picker
            v-model="detail.first_time"
            class="w200"
            :disabled="disabledFirst"
            :picker-options="pickerBeginDateBefore"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="二次进店时间" v-if="userInfo.shop_id">
          <el-date-picker

            v-model="detail.second_time"
            class="w200"
            :disabled="disabledSecond"
            :picker-options="pickerBeginDateBefore"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="首次看诊时间" >
          <el-date-picker
            v-model="detail.consultation_first_time"
            class="w200"
            :disabled="disabledFirst"
            :picker-options="pickerBeginDateBefore"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="二次看诊时间">
          <el-date-picker

            v-model="detail.consultation_second_time"
            class="w200"
            :disabled="disabledSecond"
            :picker-options="pickerBeginDateBefore"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="首次到院时间" >
          <el-date-picker
            v-model="detail.reach_first_time"
            class="w200"
            :disabled="disabledFirst"
            :picker-options="pickerBeginDateBefore"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="二次到院时间" >
          <el-date-picker

            v-model="detail.reach_second_time"
            class="w200"
            :disabled="disabledSecond"
            :picker-options="pickerBeginDateBefore"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="疑难级别">
          <el-select
            v-model="detail.difficulty_level"
            class="w200"
            clearable
            placeholder="请选择疑难级别"
          >
            <el-option
              v-for="item in options.difficulty_level"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="护理师">
          <el-select
            v-model="detail.nurse_id"
            class="w200"
            @focus="focusNurseid"
            @change="changeNurse"
            placeholder="请选择护理师"
          >
            <el-option
              v-for="item in options.nurse_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="客户标签">
          <el-cascader
            placeholder="请选择客户标签"
            class="w200"
            :options="memberList"
            :value="member_label"
            :props="props"
            @change="changeMember"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="转介绍人">
          <el-select
            v-model="detail.introducer"
            class="w200"
            @focus="focusNurseid"
            @change="changeNurse"
            placeholder="请选择介绍人"
          >
            <el-option
              v-for="item in options.nurse_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="外联人">
          <el-select
            v-model="detail.outreach_id"
            class="w200"
            @focus="focusNurseid"
            @change="changeNurse"
            placeholder="请选择外联人"
          >
            <el-option
              v-for="item in options.nurse_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="预约方式">
          <el-select
            v-model="detail.consultation_status"
            class="w200"
            @focus="focusNurseid"
            @change="changeNurse"
            placeholder="请选择预约方式"
          >
            <el-option
              v-for="item in options.consultation_status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="客户状态">
          <el-radio-group v-model="detail.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
          <div class="fz12 c999">禁用后客户账号被冻结</div>
        </el-form-item>

		<el-form-item label="转介绍手机号" prop="t_phone">
		  <el-input
		    v-model="detail.turnPhone"
		    class="w200"
		    placeholder="请填写手机号码"
        :disabled = "disabledZtellphone"
		  />
		  <div class="fz12 c999">请填写转介绍客户手机号</div>
		</el-form-item>


    <el-form-item label="显示客户余额">
      <el-radio-group v-model="detail.is_show_balance">
        <el-radio :label="1" :disabled="disabledShowBalance">是</el-radio>
        <el-radio :label="0" :disabled="disabledShowBalance">否</el-radio>
      </el-radio-group>
      <div class="fz12 c999">选择是，客户小程序会显示客户余额</div>
    </el-form-item>
    <el-form-item label="卡项数据是否准确">
      <el-radio-group v-model="detail.card_is_correct">
        <el-radio :label="1" :disabled="disabledShowCard">是</el-radio>
        <el-radio :label="0" :disabled="disabledShowCard">否</el-radio>
      </el-radio-group>
    </el-form-item>
        <el-form-item label="备注信息">
          <el-input
            v-model="detail.remarks"
            class="w200"
            placeholder="请填写备注"
            type="textarea"
          />
        </el-form-item>
      </div>

      <div class="btnBox">
        <el-button
          type="primary"
          size="mini"
          class="mainBtn"
          @click="submitForm('detail')"
          >提交</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import region from "@/components/Region";
import {
  ssettingList,
  addClient,
  updateClient,
  clientDetail,
  verifyPhone,
} from "@/api/client";
import { staffList, readAdminPositionList } from "@/api/staff";
import { getShopList } from "@/api/shop";
import { read_member_label } from "@/api/permission";
class Detail {
  customer_name = "";
  telephone = "";
  sex = 2;
  birthday = "";
  difficulty_level = "";
  nurse_id = "";
  nurse_name = "";
  province = "";
  city = "";
  region = "";
  detailed_address = "";
  source_id = "";
  source_type_id = "";
  introducer = "";
  outreach_id = "";
  shop_id = "";
  repair_progress = "";
  member_label = [];
  first_time = "";
  second_time = "";
  status = 1;
  remarks = "";
  t_phone = "";
  scar_type_position = [];
  consultation_first_time="";
  consultation_second_time="";
  reach_first_time="";
  reach_second_time="";
  consultation_status="";
  is_show_balance = 0;
  card_is_correct = 1;
}
class Rules {
  customer_name = [
    { required: true, message: "请填写客户名称", trigger: "blur" },
  ];
  telephone = [
    { required: true, message: "请填写手机号", trigger: "blur" },
    {
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      message: "手机号格式不对",
      trigger: "change",
    },
  ];
  t_phone = [
    { required: false, message: "请填写手机号", trigger: "blur" },
    {
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      message: "手机号格式不对",
      trigger: "change",
    },
  ];
  source_id = [
    { required: true, message: "请选择客户来源", trigger: "change" },
  ];
  shop_id = [{ required: true, message: "请选择归属门店", trigger: "change" }];
  type_id = [{ required: true, message: "请选择疤痕类型", trigger: "change" }];
  repair_progress = [
    { required: true, message: "请选择治疗进度", trigger: "change" },
  ];
  scar_type_position = [
    { required: true, message: "请选择疤痕资料", trigger: "change" },
  ];
}
class Options {
  source_id = [];
  source_type_id = [];
  shop_id = [];
  type_id = [];
  nurse_id = [];
  position_id = [];
  repair_progress = [];
  member_label = [];
  difficulty_level = [
    {id:1,name:'1级'},
    {id:2,name:'2级'},
    {id:3,name:'3级'},
    {id:4,name:'4级'},
  ];
  consultation_status=[
    {id:1,name:'线上'},
    {id:2,name:'到院'}
  ]
}

export default {
  components: {
    region,
  },

  data() {
    return {
      customerSourceShow : false,
      detail: new Detail(),
      rules: new Rules(),
      options: new Options(), // 选项
      id: 0,
      region: {
        province_id: "",
        city_id: "",
        district_id: "",
      },
      scarList: [],
      props: { multiple: true },
      scar_type_position: [],
      memberList: [],
      member_label: [],
      disabled: false,
      disabledFirst: false,
      disabledSecond: false,
      disabledZtellphone: false,
      disabledShowBalance:true,
      disabledShowCard:true,

      // 限制当前日期之前
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > new Date(getDateStr(0) + "23:59:59").getTime()
          );
        },
      },
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {},
  mounted() {
    this.id = this.$route.query.detail ? this.$route.query.detail.id : 0;
    this.id != 0 ? (this.disabled = true) : (this.disabled = false);
    if (this.id) {
      this.disabledFirst = this.$route.query.detail.first_time ? true : false;
      this.disabledSecond = this.$route.query.detail.second_time ? true : false;
    }
	this.turn_customerid != '' ? (this.disabledZtellphone = true) : (this.disabledZtellphone = false);
    this.id ? this.changeShop(this.$route.query.detail.shop_id) : "";
    this.getOptions();
    if(this.userInfo.shop_id){
      this.getShop('',this.userInfo.shop_id)
    }
  },

  methods: {

     blurPhone(e){
         console.log(e)
         verifyPhone({ phone:e }).then((res) => {
           console.log(res)
           if(res.shop_name != ''){

             this.$message({
               dangerouslyUseHTMLString: true,
               message: res.shop_name + '<p> 请勿重复添加<p/> '
             });
           }
         });
       },

     changeSource(e){
       this.detail.source_type_id = "";
      if(e == 665){
          this.customerSourceShow = true;
      }else{
          this.customerSourceShow = false;
      }
    },
    // 改变疤痕资料
    changeOp(e) {
      let arr = [];
      e.forEach((item) => {
        let obj = {};
        obj["type_id"] = item[0];
        obj["position_id"] = item[1];
        arr.push(obj);
      });
      this.scar_type_position = e;
      this.detail.scar_type_position = arr;
    },
    // 改变客户标签
    changeMember(e) {
      this.member_label = e;
      this.detail.member_label = e.map((i) => {
        return i[1];
      });
    },
    // 获取选项
    getOptions() {
      let that = this;
      ssettingList({ page: 1, limit: 100000 }).then((res) => {
        res.list.forEach(function (item) {
          if (item.category_id == 1) {
            that.options.type_id.push(item);
          } else if (item.category_id == 2) {
            that.options.position_id.push(item);
          } else if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 5) {
            that.options.repair_progress.push(item);
          }else if (item.category_id == 15) {
            that.options.source_type_id.push(item);
          }
        });

        that.id ? that.changeShop(that.$route.query.detail.shop_id) : "";
        that.id ? that.getDetail() : that.assembleData();
      });
      //客户标签
      read_member_label().then((res) => {
        let tempList1 = [];
        tempList1 = res.data.list.map((item) => {
          let tempList2 = [];
          item.children.forEach((val) => {
            let obg = {};
            obg["value"] = val.id;
            obg["label"] = val.name;
            tempList2.push(obg);
          });
          return Object.assign(
            {},
            { value: item.id, label: item.name, children: tempList2 }
          );
        });
        this.memberList = tempList1;
      });
    },
    // 门店列表
    getShop(e,id) {
      getShopList({ page: 1, limit: 1000000, shopName: e ,shopId:id}).then((res) => {
          this.options.shop_id = res.list;
         if(id){
          this.detail.shop_name = res.list[0].shopName
          this.detail.shop_id = res.list[0].shopId
          this.changeShop(this.detail.shop_id)
        }
      });
    },
    // 选择门店
    async changeShop(id) {
      // 获取门店下员工
      staffList({ shopId: id, page: 1, limit: 1000000 }).then((res) => {
        this.options.nurse_id = res.list;
      });
    },
    //选择护理师之前
    focusNurseid() {
      if (!this.detail.shop_id) {
        this.$message({
          message: "请先选择门店哦~",
          type: "warning",
        });
      }
    },
    //  选择护理师
    changeNurse(e) {
      this.options.nurse_id.forEach((item) => {
        if (item.id == e) {
          this.detail.nurse_name = item.name;
        }
      });
    },
    // 获取详情
    getDetail() {
      let that = this;
      this.detail = this.$route.query.detail;
      console.log(this.detail,"11")
      console.log(this.detail);
      that.options.source_id= [];
      that.options.source_type_id= [];
      this.detail.setting.forEach(function (item) {
           if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 15) {
            that.options.source_type_id.push(item);
          }
      });

      this.disabledShowBalance = this.detail.disabledShowBalance;


      this.disabled = this.detail.is_disabled_source;


      if(this.detail.source_type_id >0){
          this.customerSourceShow = true;
      }
      if(this.detail.card_is_correct == 0){
        this.disabledShowCard = false;
	
      }
      const { province, city, region } = this.detail;
      this.region = {
        province: {
          id: parseInt(province),
        },
        city: {
          id: parseInt(city),
        },
        district: {
          id: parseInt(region),
        },
      };
      this.region.province_id = parseInt(province) || "";
      this.region.city_id = parseInt(city) || "";
      this.region.district_id = parseInt(region) || "";
      this.detail.introducer = parseInt(this.detail.introducer) >0 ? parseInt(this.detail.introducer):'';
      this.detail.outreach_id = parseInt(this.detail.outreach_id) >0 ? parseInt(this.detail.outreach_id):'';
      this.detail.difficulty_level = parseInt(this.detail.difficulty_level) >0 ? parseInt(this.detail.difficulty_level):'';
      if(this.detail.turn_customerid>0){
        this.disabledZtellphone = true
      }else{
        this.disabledZtellphone = false
      }
      var arr = [];
      this.detail.scar_type_position.forEach((item) => {
        let a = [item.type_id, item.position_id];
        arr.push(a);
      });
      this.scar_type_position = arr;
      this.member_label = this.detail.member_label;
      this.changeMember(this.member_label);
      this.assembleData();
    },
    // 调整疤痕资料数据类型
    assembleData() {
      let scarList = [];
      let tempList1 = [];
      let tempList2 = [];
      tempList1 = this.options.type_id.map((item) => {
        return Object.assign({}, { value: item.id, label: item.name });
      });
      tempList2 = this.options.position_id.map((item) => {
        return Object.assign({}, { value: item.id, label: item.name });
      });
      tempList1.forEach((item) => {
        item.children = tempList2;
        scarList.push(item);
      });
      this.scarList = scarList;
    },
    // 获取地区
    getRegion(region) {
      const { province_id, city_id, district_id } = region;
      this.detail.province = province_id;
      this.detail.city = city_id;
      this.detail.region = district_id;
    },
    // 提交图片
    handleUpload() {
      this.$refs.uploader.goToUpload();
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
		    if (valid) {
          if (!this.detail.scar_type_position.length) {
            this.$message({
              message: "请添加疤痕资料",
              type: "error",
            });

            return false;
          }

          if(this.detail.first_time && !this.detail.birthday){
            this.$message({
              message: "请添加顾客生日",
              type: "error",
            });

            return false;
          }

          if(this.detail.first_time && !this.detail.nurse_id){
            this.$message({
              message: "请添加护理师",
              type: "error",
            });

            return false;
          }

          this.hint(this.id ? "更新" : "添加");

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint(text) {
      this.$confirm(`确定${text}此客户信息吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 确认提交
    confirm() {
      if (this.id) {
        updateClient({ ...this.detail }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.back();
        });
      } else {
        addClient({ ...this.detail }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.$router.go(-1);
        });
      }
    },
    // 返回
    back() {
      const query = {
        detail: this.$route.query.detail,
        id: this.$route.query.detail.id,
        activeName: "first",
      };
      this.$router.push({ name: "客户详情", query });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 14px;
  line-height: 34px;
  color: #666;
  margin-bottom: 20px;
}
.form {
  display: flex;
  flex-wrap: wrap;

  ::v-deep .el-form-item {
    margin-right: 20px;
    display: flex;
  }
  ::v-deep .el-form-item {
    width: 30%;
  }
  ::v-deep .el-form-item--mini .el-form-item__label {
    // width: 112px;
    width: 125px;
  }
  ::v-deep .region .el-select {
    width: 100px;
  }
}

.btnBox {
  width: 100%;
  text-align: center;
  .mainBtn {
    padding: 6px 40px;
    margin-top: 20px;
  }
}
.center {
  width: 100% !important;
}
.scarBox {
  display: flex;
  .imgBox {
    margin-left: 100px;
    // padding: 30px;
    // background-color: pink;
  }
}
</style>
